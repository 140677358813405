'use client';

import { BookingCtaStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { render } from 'storyblok-rich-text-react-renderer';
import Link from 'next/link';
import { makePrestazioneRoute } from '@/routes';
import Image from 'next/image';
import locationMarkerIcon from '@/assets/location-marker-icon.svg';
import chevronRightIcon from '@/assets/service-card-chevron-right-icon.svg';
import clsx from 'clsx';
import { fireGTMEvent } from '@/gtm';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '../utils/richText';

const OTHER_CITIES = Symbol.for('other-cities');

export const StoryblokBookingCta: StoryblokReactComponent<BookingCtaStoryblok> = ({ component, story }) => {
  const cities: Array<typeof OTHER_CITIES | string> = component.cities ?? [];
  if (cities.length % 2 !== 0) {
    cities?.push(OTHER_CITIES);
  }
  return (
    <div className="bg-neutral-surface border rounded-3xl shadow-xl p-8 flex flex-col gap-6 mx-4" {...storyblokEditable(component)}>
      <h2>{render(component.title, makeRichTextRenderOptions(story))}</h2>
      <ul
        className={clsx(
          'flex flex-col lg:grid gap-4',
          cities.length === 6 ? 'lg:grid-cols-3' : cities.length === 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-4',
        )}
      >
        {cities.map(city => (
          <li aria-label={city === OTHER_CITIES ? 'altre città' : city} key={city === OTHER_CITIES ? 'other-cities' : city}>
            <Link
              href={city === OTHER_CITIES ? '/' : makePrestazioneRoute(city, component.speciality || component.manualSpeciality || '')}
              onClick={() => {
                fireGTMEvent('blog_click_città', { city: city === OTHER_CITIES ? 'Altre città' : city, speciality: component.speciality });
              }}
              className="flex flex-row items-center gap-3 py-3 px-5 transition-colors rounded-xl shadow-sm border group hover:border-brandGreen-500"
            >
              <Image src={locationMarkerIcon} alt="icona indicatore di posizione" aria-hidden />
              <span className="capitalize flex-grow text-xs text-neutral-lighter">{city === OTHER_CITIES ? 'Altre città' : city}</span>
              <Image
                className="transition-transform translate-x-0 group-hover:translate-x-1"
                src={chevronRightIcon}
                alt="icona freccia destra"
                aria-hidden
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
