import { CardsSectionCardStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import Image from 'next/image';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { storyblokEditable } from '@storyblok/js';

export const Card: StoryblokReactComponent<CardsSectionCardStoryblok> = ({ component: card, story }) => {
  return (
    <Link
      href={resolveWebLink(card.link, { component: card, story })}
      key={card._uid}
      className="rounded-lg bg-white border border-black/5 w-full md:w-[calc(50%-1rem)] lg:w-[calc(33.33%-1rem)] flex-shrink-0 shadow-1 py-10 px-6 flex flex-col gap-8 justify-between translate-y-0 hover:-translate-y-1 transition-transform group"
      {...storyblokEditable(card)}
    >
      <h3 className="flex-grow text-xl font-medium w-4/6">{card.title}</h3>
      <div className="relative h-28">
        <Image src={card.image.filename} alt={card.image.alt ?? ''} fill className="object-contain object-left" />
      </div>
      <p className="text-text-body font-light">{card.description}</p>
      <div className="flex flex-row items-center gap-3 ">
        <span className="transition-colors group-hover:text-elty-green">Scopri di più</span>
        <svg
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="transition-transform translate-x-0 group-hover:translate-x-2 delay-100"
        >
          <circle cx="15.7778" cy="15.179" r="15" transform="rotate(180 15.7778 15.179)" fill="#F4F8FF" />
          <rect width="12" height="12" transform="translate(10.0779 9.07159)" fill="#F4F8FF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.218 14.8064C18.3645 14.9529 18.3645 15.1903 18.218 15.3368L14.468 19.0868C14.3216 19.2332 14.0842 19.2332 13.9377 19.0868C13.7913 18.9403 13.7913 18.7029 13.9377 18.5564L17.4226 15.0716L13.9377 11.5868C13.7913 11.4403 13.7913 11.2029 13.9377 11.0564C14.0842 10.91 14.3216 10.91 14.468 11.0564L18.218 14.8064Z"
            fill="#031323"
          />
        </svg>
      </div>
    </Link>
  );
};
