'use client';
import { HomeHeroBannerDavinciInfoFormStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponentProps } from '../componentsMap';
import { getImageDimensions } from '@/storyblok/utils/asset';
import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';
import { NODE_PARAGRAPH, render } from 'storyblok-rich-text-react-renderer';
import { FormEvent, Suspense } from 'react';
import { FormResult, makeSearchParamKey } from './davinciInfoForm/utils';
import { storyblokEditable } from '@storyblok/js';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

export function StoryblokHomeHeroBannerDavinciInfoForm(props: StoryblokReactComponentProps<HomeHeroBannerDavinciInfoFormStoryblok>) {
  // https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
  return (
    <Suspense>
      <StoryblokHomeHeroBannerDavinciInfoFormContent {...props} />
    </Suspense>
  );
}

function StoryblokHomeHeroBannerDavinciInfoFormContent({
  component,
  story,
}: StoryblokReactComponentProps<HomeHeroBannerDavinciInfoFormStoryblok>) {
  const searchParams = useSearchParams();
  const result = searchParams.get(makeSearchParamKey(component.formId));
  const router = useRouter();
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const data = new FormData(e.target as HTMLFormElement);
    fetch('/api/contact-form-submit', {
      method: 'POST',
      body: data,
    })
      .then(response => response.text())
      .then(location => router.replace(location));
  };
  return (
    <form
      onSubmit={onSubmit}
      className="mx-4 md:mx-14 lg:ml-20 xl:mx-auto xl:max-w-screen-xl xl:w-full xl:flex xl:flex-row relative z-[3] -top-14 md:-top-40 lg:top-0 "
      {...storyblokEditable(component)}
    >
      <div className="shadow-1 rounded-xl bg-white p-6 flex flex-col gap-6 relative border border-black/5">
        <span className="hidden lg:inline-block lg:text-2xl">{render(component.title, makeRichTextRenderOptions(story))}</span>
        <span className="lg:hidden text-xl">
          {render(
            component.title,
            makeRichTextRenderOptions(story, {
              nodeResolvers: {
                [NODE_PARAGRAPH]: children => <span className="[&:not(:first-child)]:ml-2">{children}</span>,
              },
            }),
          )}
        </span>

        <div className="flex flex-col lg:flex-row gap-3">
          <div className="flex flex-row gap-2 rounded-lg bg-[#F5F5F8] relative">
            <Image
              className="absolute left-5 h-full"
              aria-hidden
              src={component.emailIcon.filename}
              alt={component.emailIcon.alt ?? ''}
              {...getImageDimensions(component.emailIcon)}
            />
            <input
              name="email"
              required
              type="email"
              className="bg-inherit py-4 pl-12 pr-2 rounded-lg lg:w-44"
              placeholder={component.emailPlaceholder}
            />
          </div>
          <div className="flex flex-row gap-2 rounded-lg bg-[#F5F5F8] relative">
            <Image
              className="absolute left-5 h-full"
              aria-hidden
              src={component.phoneIcon.filename}
              alt={component.phoneIcon.alt ?? ''}
              {...getImageDimensions(component.phoneIcon)}
            />
            <input
              name="phone"
              required
              type="tel"
              className="bg-inherit py-4 pl-12 pr-2 rounded-lg lg:w-44"
              placeholder={component.phonePlaceholder}
            />
          </div>
          <input type="hidden" name="portalId" value={component.portalId} />
          <input type="hidden" name="formId" value={component.formId} />
          <input type="hidden" name="privacyText" value={component.privacyText} />
          <button
            disabled={(result && result === FormResult.Success) || false}
            className="py-4 px-10 rounded-lg bg-elty-blue text-white transition-colors hover:bg-primary-hover disabled:bg-[#F5F5F8] disabled:cursor-not-allowed disabled:text-text-body"
            type="submit"
          >
            {component.submitText}
          </button>
        </div>
        <div className="flex flex-row gap-2">
          <input name="privacy" required type="checkbox" id={`privacy-${component._uid}`} />
          <label className="text-xs text-text-body" htmlFor={`privacy-${component._uid}`}>
            {component.privacyText}
          </label>
        </div>
        {result && result === FormResult.Success && (
          <div className="rounded bg-[#0BBCA74D] py-1 flex flex-row items-center gap-2.5 px-2">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4881 3.92993C13.5629 3.99406 13.6244 4.0723 13.6689 4.16019C13.7135 4.24808 13.7403 4.34389 13.7478 4.44215C13.7554 4.5404 13.7434 4.63918 13.7128 4.73283C13.6821 4.82647 13.6333 4.91316 13.5691 4.98793L7.56908 11.9879C7.50181 12.0664 7.41904 12.1301 7.32598 12.1751C7.23291 12.2201 7.13155 12.2453 7.02826 12.2493C6.92497 12.2532 6.82198 12.2358 6.72575 12.198C6.62952 12.1603 6.54214 12.1031 6.46908 12.0299L2.96908 8.52993C2.83253 8.38841 2.75702 8.19893 2.75882 8.00228C2.76062 7.80563 2.83959 7.61756 2.97871 7.47857C3.11783 7.33958 3.30598 7.26079 3.50263 7.25917C3.69928 7.25756 3.88869 7.33324 4.03008 7.46993L6.95808 10.3969L12.4311 4.01193C12.5606 3.86112 12.7446 3.76787 12.9428 3.75268C13.141 3.73749 13.3371 3.80061 13.4881 3.92993Z"
                fill="#0BBCA7"
              />
            </svg>

            <span className="text-xs">{component.successMessage}</span>
          </div>
        )}
        {result && result === FormResult.Error && (
          <div className="rounded bg-red-100 py-1 flex flex-row items-center gap-2.5 px-2">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.00016 3.16683C6.58567 3.16683 5.22912 3.72873 4.22893 4.72893C3.22873 5.72912 2.66683 7.08567 2.66683 8.50016C2.66683 9.91465 3.22873 11.2712 4.22893 12.2714C5.22912 13.2716 6.58567 13.8335 8.00016 13.8335C9.41465 13.8335 10.7712 13.2716 11.7714 12.2714C12.7716 11.2712 13.3335 9.91465 13.3335 8.50016C13.3335 7.08567 12.7716 5.72912 11.7714 4.72893C10.7712 3.72873 9.41465 3.16683 8.00016 3.16683ZM1.3335 8.50016C1.3335 4.81816 4.31816 1.8335 8.00016 1.8335C11.6822 1.8335 14.6668 4.81816 14.6668 8.50016C14.6668 12.1822 11.6822 15.1668 8.00016 15.1668C4.31816 15.1668 1.3335 12.1822 1.3335 8.50016ZM5.1955 5.6955C5.32051 5.57052 5.49005 5.5003 5.66683 5.5003C5.84361 5.5003 6.01314 5.57052 6.13816 5.6955L8.00016 7.5575L9.86216 5.6955C9.92366 5.63182 9.99722 5.58103 10.0786 5.54609C10.1599 5.51116 10.2474 5.49276 10.3359 5.492C10.4244 5.49123 10.5122 5.50809 10.5941 5.54161C10.6761 5.57514 10.7505 5.62464 10.8131 5.68723C10.8757 5.74983 10.9252 5.82426 10.9587 5.90619C10.9922 5.98812 11.0091 6.07591 11.0083 6.16443C11.0076 6.25295 10.9892 6.34043 10.9542 6.42177C10.9193 6.5031 10.8685 6.57666 10.8048 6.63816L8.94283 8.50016L10.8048 10.3622C10.9263 10.4879 10.9935 10.6563 10.9919 10.8311C10.9904 11.0059 10.9203 11.1731 10.7967 11.2967C10.6731 11.4203 10.5059 11.4904 10.3311 11.4919C10.1563 11.4935 9.9879 11.4263 9.86216 11.3048L8.00016 9.44283L6.13816 11.3048C6.01243 11.4263 5.84403 11.4935 5.66923 11.4919C5.49443 11.4904 5.32722 11.4203 5.20362 11.2967C5.08001 11.1731 5.0099 11.0059 5.00838 10.8311C5.00686 10.6563 5.07406 10.4879 5.1955 10.3622L7.0575 8.50016L5.1955 6.63816C5.07052 6.51314 5.0003 6.34361 5.0003 6.16683C5.0003 5.99005 5.07052 5.82051 5.1955 5.6955Z"
                fill="#DC0C0D"
              />
            </svg>
            <span className="text-xs">{component.errorMessage}</span>
          </div>
        )}
      </div>
    </form>
  );
}
