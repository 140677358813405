import { RichtextStoryblok, SectionStoryblok, StoryblokComponent as StoryblokComponentType } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import { JSXElementConstructor, PropsWithChildren } from 'react';
import { titleToElementId } from '../post/utils';
import { richTextToString } from '@/storyblok/utils/richText';
import Link from 'next/link';
import Image from 'next/image';
import { getImageDimensions } from '@/storyblok/utils/asset';
import { storyblokEditable } from '@storyblok/js';
import { captureMessage } from '@/reporting/captureMessage';
import { StoryblokStory } from 'storyblok-generate-ts';
import { buildMessageContext } from '@/reporting/buildMessageContext';
import { StoryblokComponent } from '../storyblokComponent';

export const StoryblokSection: StoryblokReactComponent<SectionStoryblok> = ({ component, story }) => {
  return (
    <section className="px-4" {...storyblokEditable(component)}>
      <NodeRenderer component={component} node={component.body} story={story} />
    </section>
  );
};

const NodeRenderer = ({
  node,
  story,
  component,
}: {
  node?: RichtextStoryblok;
  parentNode?: RichtextStoryblok;
  story: StoryblokStory<StoryblokComponentType>;
  component: SectionStoryblok;
}) => {
  if (!node) return null;
  const Component = renderer[node.type];
  if (!Component) {
    captureMessage(`Component ${node.type} not found in renderer`, { contexts: buildMessageContext({ story, component }) });
    return null;
  }
  return <Component node={node} story={story} component={component} />;
};

const MarkRenderer = ({
  node,
  children,
  story,
  component,
}: PropsWithChildren<{ node?: RichtextStoryblok; story: StoryblokStory<StoryblokComponentType>; component: SectionStoryblok }>) => {
  if (!node) return null;
  const marks = node.marks ?? [];
  const [mark, ...rest] = marks;
  if (!mark) return <>{children}</>;
  const Component = marksRenderer[mark.type];
  if (!Component) {
    captureMessage(`Component ${mark.type} not found in marksRenderer`, { contexts: buildMessageContext({ story, component }) });
    return <>{children}</>;
  }
  return (
    <Component node={node} mark={mark} story={story} component={component}>
      <MarkRenderer node={{ ...node, marks: rest }} story={story} component={component}>
        {children}
      </MarkRenderer>
    </Component>
  );
};

const renderer: Record<
  string,
  JSXElementConstructor<{ node: RichtextStoryblok; story: StoryblokStory<StoryblokComponentType>; component: SectionStoryblok }>
> = {
  doc: ({ node, story, component }) => {
    return <>{node.content?.map((child, i) => <NodeRenderer key={i} node={child} story={story} component={component} />)}</>;
  },
  paragraph: ({ node, story, component }) => {
    return (
      <p className="mb-6 font-normal">
        {node.content?.map((child, i) => <NodeRenderer key={i} node={child} story={story} component={component} />)}
      </p>
    );
  },
  heading: ({ node, story, component }) => {
    const level = node.attrs?.level ?? 1;
    const El = `h${level}` as keyof JSX.IntrinsicElements;
    return (
      <El
        className="text-center md:text-left scroll-mt-[calc(var(--header-height)+1rem)] text-2xl md:text-3xl font-medium text-brandBlue-900 mb-10"
        id={titleToElementId(richTextToString(node))}
      >
        {node.content?.map((child, i) => <NodeRenderer key={i} node={child} story={story} component={component} />)}
      </El>
    );
  },
  text: ({ node, story, component }) => {
    return (
      <MarkRenderer node={node} story={story} component={component}>
        {node.text}
      </MarkRenderer>
    );
  },
  bullet_list: ({ node, story, component }) => {
    return (
      <ul className="list-disc pl-4">
        {node.content?.map((child, i) => <NodeRenderer key={i} node={child} story={story} component={component} />)}
      </ul>
    );
  },
  ordered_list: ({ node, story, component }) => {
    return (
      <ol className="list-decimal pl-4">
        {node.content?.map((child, i) => <NodeRenderer key={i} node={child} story={story} component={component} />)}
      </ol>
    );
  },
  list_item: ({ node, story, component }) => {
    return <li>{node.content?.map((child, i) => <NodeRenderer key={i} node={child} story={story} component={component} />)}</li>;
  },
  hard_break: () => <br />,
  image: ({ node, story, component }) => {
    const src = node.attrs.src;
    const alt = node.attrs.alt ?? '';
    if (!src) {
      captureMessage('Missing image src attr', { contexts: buildMessageContext({ story, component }) });
      return null;
    }
    if (!alt) {
      captureMessage('Missing image alt attr', { contexts: buildMessageContext({ story, component }) });
    }
    return <Image src={src} alt={alt} {...getImageDimensions({ filename: src })} />;
  },
  emoji: ({ node, story, component }) => {
    if (!node.attrs.emoji) {
      captureMessage('Missing emoji attr', { contexts: buildMessageContext({ story, component }) });
      return null;
    }
    return <>{node.attrs.emoji}</>;
  },
  blok: ({ node, story }) => {
    const [component] = node.attrs.body as Array<StoryblokComponentType>;
    if (component?.component === 'htmlEmbed') {
      return <StoryblokComponent component={component} story={story} />;
    }
    return null;
  },
};

const marksRenderer: Record<
  string,
  JSXElementConstructor<
    PropsWithChildren<{
      node: RichtextStoryblok;
      mark: { type: string; [k: string]: any };
      story: StoryblokStory<StoryblokComponentType>;
      component: SectionStoryblok;
    }>
  >
> = {
  link: ({ mark, children, story, component }) => {
    const href = mark?.attrs?.href;
    const target = mark?.attrs?.target;
    if (typeof href !== 'string') {
      captureMessage('Missing link href attr', { contexts: buildMessageContext({ story, component }) });
      return <>{children}</>;
    }

    return (
      <Link className="underline text-secondary" href={href} target={target}>
        {children}
      </Link>
    );
  },
  superscript: ({ children }) => <sup>{children}</sup>,
  bold: ({ children }) => <strong className="font-semibold">{children}</strong>,
  textStyle: ({ children }) => <>{children}</>,
  italic: ({ children }) => <em>{children}</em>,
  underline: ({ children }) => <u>{children}</u>,
  anchor: ({ mark, children }) => <span id={mark.attrs.id}>{children}</span>,
};
