'use client';

import { useRef } from 'react';
import { LogosStripBigStoryblok } from '@/types/types-storyblok';
import { StoryblokLogosStripBigRow } from './row';
export const StoryblokLogosStripBigRows = ({ component }: { component: LogosStripBigStoryblok }) => {
  const hovering = useRef(false);

  return (
    <>
      {component.rows.map((row, index) => (
        <StoryblokLogosStripBigRow key={row._uid} row={row} index={index} hovering={hovering} />
      ))}
    </>
  );
};
